<template>
  <div>
    <el-dialog
      title="预约信息验证"
      :visible.sync="idVerify.visible"
      width="300px"
      center
    >
      <div><span>请使用身份证后四位验证预约信息</span></div>
      <div style="font-size: 12px; margin-top: 5px">
        <span
          >Please Enter Last Four Number of Your ID Card to Confirm Your Booking
          Information</span
        >
      </div>
      <div class="code-input-wrap">
        <input class="real-code-input" maxlength="4" v-model="verifyCode" />
        <input class="code-input" type="text" :value="verifyCode" readonly />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="warning" @click="requestVerify" style="width: 100%"
          >确认<br />Confirmed</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import request from '@/api/request'
import api from "@/api/api"
import { mapState, mapGetters, mapMutations } from "vuex"

export default {
  name: "IDVerify",
  props: ["promise"],
  computed: {
    ...mapState(['idVerify']),
  },
  data() {
      return {
          verifyCode: '',
          loading: 0,
      }
  },
  methods: {
    ...mapMutations([
        'setIDVerified',
        'setIDVerifyVisiable',
        'setIDVerifyCode',
        'setIDVerifyOdid',
    ]),
    requestVerify() {
      if (this.loading) return;
      this.loading = 1
      const loading = this.$loading({
        lock: true,
        text: '正在验证请稍等...',
        spinner: 'el-icon-loading',
        background: 'rgba(200, 200, 200, 0.5)'
      })
      return api.checkJoin({
        code: this.verifyCode,
      }).then(r => {
        this.loading = 0
        loading.close()
        if ( 1111*0 /* 调试跳过验证 */ || r.data.status == 200) {
          this.setIDVerifyVisiable(false)
          this.setIDVerified(true)
          this.setIDVerifyOdid(r.data.data)
          this.promise.resolve(true)
        } else {
          this.$message(r.data.message)
          this.promise.reject(false)
        }
      }).catch(e => {
        this.loading = 0
        loading.close()
        this.$message('验证失败')
        this.promise.reject(false)
      })
    },
  },
};
</script>

<style scoped>

.code-input-wrap {
  position: relative;
}

.code-input {
  position: relative;
  z-index: 1;
  height: 63px;
  line-height: 60px;
  width: 240px;
  display: block;
  border: none;
  background: url("../assets/input4.png") no-repeat ;
  background-size: 240px;
  margin: 10px -44px 0 8px;
  letter-spacing: 44px;
  font-size: 30px;
  outline: none;
  padding: 0 0 0 16px;
  overflow: hidden;
}

.real-code-input {
  z-index: 100;
  position: absolute;
  height: 63px;
  line-height: 60px;
  width: 240px;
  top: 0;
  left: -25px;
  display: block;
  overflow: hidden;
  border: none;
  outline: none;
  opacity: 0;
  background: #ffe;
  margin: 0;
  padding: 0 0 0 20px;
}
</style>

