<template>
  <div>
    <el-dialog
      title="用户调研"
      :visible.sync="openQuestion.visible"
      width="310px"
      top="30px"
      custom-class="open-question"
      :show-close="false"
      center
    >
      <div>
        <span>{{ openQuestion.title }}</span>
      </div>
      <div class="options">
        <div v-for="(item, index) in openQuestion.content" :key="index">
          <div v-html="option(item)" 
            class="item"
            @click="pick(index)"
            :class="{ selected: -1 !== (openQuestion.picked || []).indexOf(index) ,
            }"
          ></div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer" v-if="(openQuestion.picked||[]).length > 0">
        <el-button type="primary" style="width: 100%" @click="submit"
          >确认<br />Confirmed</el-button
        >
      </span>
      <span slot="footer" class="dialog-footer disabled" v-else>
        <el-button type="info" style="width: 100%"
          disabled>确认<br />Confirmed</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import request from '@/api/request'
import { mapState, mapGetters, mapMutations } from "vuex"

export default {
  name: "OpenQuestion",
  props: ["promise"],
  computed: {
    ...mapState(['openQuestion']),
    answer() {
      let data = [{
          qid: this.openQuestion.qid,
          answer: this.openQuestion.picked.join(',')
      }]
      return JSON.stringify(data)
    },
  },
  methods: {
    ...mapMutations([
        'setOpenQuestion', 
        'setOpenQuestionPicked', 
        'setOpenQuestionVisiable',
        'setOpenQuestionAnswered',
    ]),
    option(v) {
      return v;
      //   let lines = v.split(',')
      //   return `<span>${lines[0]}</span><span>${lines[1]}</span>`;
    },
    submit() {
      request.post('customize/sports-museum/submitquests', {
        aid: 942,
        answer: this.answer
      }).then(r => {
        this.promise.resolve(true)
        this.setOpenQuestionVisiable(false)
        this.setOpenQuestionAnswered(true)
      }).catch(e => {
        this.$message('提交问题出错')
        this.promise.reject(false)
      })
    },
    pick(index) {
      this.setOpenQuestionPicked(index)
    },
  },
};
</script>

<style scoped>
.selected {
    background-color: rgba(187, 138, 76, 1);
    color: #FFFFFF;
}
.options {
    margin-top: 10px;
}
.options > div {
    height: 30px;
    line-height: 30px;
    background: #eee;
    margin-top: 3px;
    font-size:13px;
    color: #433C30;
    background-color: rgba(255, 233, 188, 0.9);
}
.item {
    padding-left: 10px;
}
</style>

<style>
.open-question {
    background: #fcfcfc;
}
.open-question .el-dialog__body {
    padding: 8px 20px;
}
.open-question .el-icon-close {
    display: none;
}
</style>
