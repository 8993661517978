<template>
  <div class="login apppadding">
    <div class="login-title" v-if="admin">
      <div>上海体育博物馆预约核销系统</div>
    </div>
    <div class="login-title" v-else>
      <div>上海体育博物馆知识问答</div>
      <div>Q&As of Shanghai Sports Museum</div>
    </div>
    <div class="phone" >
      <input v-model="mobile" placeholder="请输入手机号"/>
    </div>
    <div class="code">
      <input v-model="smscode" placeholder="请输入验证码" />
      <div v-if="disabled" class="vifycode">{{countDown}} 秒再获取</div>
      <div v-else class="vifycode" @click="sendCode">获取验证码</div>
    </div>

    <div class="go" @click="verifyCode">立即登录 <span>→</span></div>
  </div>
</template>

<script>
import api from "@/api/api"
import router from "@/router"
import { mapState, mapGetters, mapMutations, mapActions } from "vuex"

export default {
  name: "Login",

  data() {
    return {
      mobile: '',
      smscode: '',
      timer: null,
      countDown: 60,
      disabled: false
    };
  },

  computed: {
    admin() {
      return this.$route.query.admin > 0
    }
  },

  methods: {
    ...mapMutations(["setUser", "setRole"]),
    ...mapGetters(["getUser", "getRole"]),
    sendCode() {
      this.disabled = true
      this.timer = setInterval(() => {
        if (!this.countDown--) {
          this.disabled = false
          this.countDown = 60
          clearInterval(this.timer)
        }
      }, 1000)
      api.sendCode(this.mobile).then(d => {
        if (d.data.statusCode != 200) {
          this.$message({
            message: d.data.message,
            type: 'warning'
          })
          this.disabled = false
          this.countDown = 60
          clearInterval(this.timer)
        } else {
          this.$message({
            message: '验证码已发送',
            type: 'success'
          })
        }
      })
    },
    verifyCode() {
      api.verifyCode(this.mobile, this.smscode).then((d) => {
        if (d.data.data === false) {
          this.$message({
            message: '验证码错误',
            type: 'warning'
          })
          return 
        }
        if (d.data.statusCode != 200) {
          this.$message({
            message: d.data.message,
            type: 'warning'
          })
        } else {
          this.setUser(d.data.data)
          this.load()
        }
      })
    },
    load() {
      api.userInfo().then(r => {
        if (r.data.status == 200) {
          this.setRole(r.data.data.role)
            if (this.getRole() == 2) {
            this.$router.push({
              path: '/admin'
            })
          } else {
            this.$router.push({name: 'Main'})
          }
        }
      })
    }
  },
  mounted() {
    if (!this.$route.query.logout) {
      this.load()
    }
  }
};
</script>

<style scoped lang="less">
.apppadding{
  padding-top: 30px;
}

.login{
  color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.login .login-title{
  font-weight: 400;
  letter-spacing: 0;
  padding: 30px 0 80px 0;
}
.login .login-title>div:first-child{
  font-size: 28px;
}
.login .login-title>div:nth-child(2){
  font-size: 20px;
}
.login .phone{
  width: 330px;
  margin-bottom: 20px;
}
.login .phone input{
  width: 330px;
  height: 56px;
  box-sizing: border-box;
  padding: 2px 2px 2px 2px;
  font-weight: 400;
  font-size: 14px;
  color: rgb(153, 153, 153);
  text-align: center;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: -2px 2px 5px rgb(128 128 128 / 35%);
  border: none;
  border-radius: 8px;
}
.login .code{
  display: flex;
  align-items: center;
  width: 330px;
  justify-content: space-between;
  margin-bottom: 30px;
}
.login .code input{
  width: 181px;
  height: 56px;
  box-sizing: border-box;
  padding: 2px 2px 2px 2px;
  font-weight: 400;
  font-size: 14px;
  color: rgb(153, 153, 153);
  text-align: center;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: -2px 2px 5px rgb(128 128 128 / 35%);
  border: none;
  border-radius: 8px;
}
.login .code .vifycode{
  width: 140px;
  height: 56px;
  background-color: rgba(255, 255, 255, 1);
  border: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-style: normal;
  color: #D6C9AC;
}
.login .go{
  font-weight: 700;
  font-size: 22px;
}
.login .go span{
  font-weight: 400;
}
</style>

