<template>
  <div class="cert-body">
    <div class="cert-title">
      <div>纪念品凭证</div>
      <div>Souvenir Certificate</div>
    </div>

    <div class="cert-list">
      <div class="cert-item">
        <div class="item-t">序列号：{{detail.orderno_str}}</div>
        <div class="cert-code"><Code :value="detail.orderno" /></div>
      </div>
      <div class="cert-obj">
        <div>
          <div>{{detail.jname}}</div>
          <div>{{detail.jname_en}}</div>
        </div>
        <div>
          <div>数量：1</div>
          <div>Amount： 1</div>
        </div>
      </div>
      <div class="cert-time">
        <div>
          <div>仅限 {{ detail.recorddate }} 23:59:59 前可使用</div>
          <div>{{ detail.recorddate }}  Valid Only</div>
        </div>
        <div class="used" v-if="detail.transferstate > 0">
          已核销
        </div>
      </div>
      <div class="cert-footer">
        <!--        <div class="mvbutton confirm" @click="saveimage">-->
        <!--          <div>保存至相册</div>-->
        <!--          <div>SAVE</div>-->
        <!--        </div>-->
        <div class="mvbutton confirm" @click="gohome">
          <div>返回首页</div>
          <div>HOME PAGE</div>
        </div>
      </div>
      <div class="cert-notice">长按二维码保存到相册</div>

    </div>
  </div>
</template>

<script>
import Code from '@/components/code'
import api from "@/api/api";

export default {
  name: "Redemption",
  components:{
    Code
  },
  data(){
    return {
      msg: 'hello vue',
      codes:'',
      detail:{}
    }
  },
  methods: {
    gohome(){
      this.$router.push({path:'/'});
    },
    getData()
    {
      api.getJackorder({jid:this.jid}).then((d) => {
        if(d.data.status == 200)
        {
          this.detail = d.data.data
        }
      })
    },
  },

  mounted(){
    this.jid = this.$route.query.jid
    this.getData()
    setInterval(this.getData, 5000)
  }
}
</script>

<style scoped lang="less">

.cert-body{
  /*background-color: rgba(214, 201, 172, 1);*/
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
}

.cert-subtitle
{
  font-size: 16px;
  margin-top: 30px;
}
.cert-body .cert-title>div:first-child{
  font-size: 30px;
  font-weight: 400;
}
.cert-body .cert-title>div:nth-child(2){
  font-size: 24px;
}
.cert-body .cert-list{
  box-sizing: border-box;
  padding: 10px 20px 30px 10px;
  margin-top: 20px;
  background: url("https://oss.mcloud.moveclub.cn/mpms/20210604/1622801809-60b9fd91278d6.png") no-repeat;
  //height: 464px;
  width: 100%;
  background-size: 100%;
  font-size: 14px;
  color: #606266;
}
.cert-body .cert-list .cert-item{
  margin-bottom: 20px;
  display: flex;
  font-weight: 400;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 10px;
}
.cert-body .cert-list .cert-item .item-t{
  padding-bottom: 10px;
}
.cert-body .cert-list .cert-obj{
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 10px 10px 10px;
  border-bottom: 1px solid #979797;
}
.cert-body .cert-list .cert-time{
  display: flex;
  // flex-direction: column;
  // align-items: flex-start;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 10px;
}
.cert-body .cert-list .cert-footer{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.cert-body .cert-list .cert-footer .confirm{
  width: 100%;
}
.cert-body .cert-list .cert-footer .cencel{
  width: 149px;
  background: #CFBFA4;
  color: rgba(0,0,0,0.85);
  box-shadow: 5px 5px 5px #2B2B2D;
}
.cert-notice{
  padding-top: 10px;
}

.used {
  color: #f90;
  font-size: 24px;
}
</style>
