<template>
  <div class="redem-body">
    <div class="redem-title">
      <div>兑换列表</div>
      <div>Redemption List</div>
    </div>
    <div class="redem-list">
      <div class="redem-item" v-for="(item,index) in list" :key="index" @click="jumpUrl(item)">
        <div class="redem-left">
          <div>{{item.jname}}</div>
          <div>{{item.jname_en}}</div>
        </div>
        <div class="redem-right">
          <div>兑换时间：{{item.create_time|status}}</div>
          <div>Redeem Time：{{item.create_time|status}}</div>
        </div>
      </div>
      <div class="mvbutton redem-footer" @click="gohome">
        <div>返回首页</div>
        <div>HOME PAGE</div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import api from "@/api/api";
import router from "@/router";
import moment from 'moment'

export default {
  name: "Redemption",
  filters: {
    status(time) {
      return moment(time*1000).format('YYYY-MM-DD HH:mm')
    }
  },
  data(){
    return {
      msg: 'hello vue',
      codes:'',
      list:[]
    }
  },
  methods: {
    gohome(){
      this.$router.push({path:'/'});
    },
    getData()
    {
      api.getWinList().then((d) => {
        this.list = d.data.data
      })
    },
    jumpUrl(item)
    {
      console.log(item,'item')
      if(item.type == 1)
        this.$router.push({name:'Reservcert',query:{jid:item.id}});
      else
        this.$router.push({name:'Goodscert',query:{jid:item.id}});
    }
  },

  mounted(){
    this.getData()
  }
}
</script>

<style scoped>
.redem-body{
  /*background-color: rgba(214, 201, 172, 1);*/
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
}
.redem-body .redem-title>div:first-child{
  font-size: 30px;
  font-weight: 400;
}
.redem-body .redem-title>div:nth-child(2){
  font-size: 24px;
}
.redem-body .redem-list{
  box-sizing: border-box;
  padding: 10px;
  margin-top: 20px;
  width: 100%;
}
.redem-body .redem-list .redem-item{
  width: 100%;
  height: 96px;
  background-color: rgba(187, 138, 76, 1);
  border-radius: 4px 0 4px 0;
  box-shadow: 5px 5px 5px rgb(149 104 47);
  margin-bottom: 20px;
  display: flex;
  font-weight: 400;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 10px;
}
.redem-body .redem-list .redem-item .redem-left{
  font-size: 16px;
  text-align: left;
}
.redem-body .redem-list .redem-item .redem-right{
  font-size: 12px;
}
.redem-body .redem-footer{
  width: 100%;
  height: 73px;
}
</style>
