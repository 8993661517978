<template>
  <div class="goods-body">
    <div class="score">获得积分： <strong>{{score}}</strong></div>
    <div class="goods-title">
      <div>恭喜你获得博物馆纪念品！</div>
      <div>Congratulations on winning the museum souvenir!</div>
    </div>

    <div class="goods-subtitle">
      <div>请选择纪念品</div>
      <div>Please choose a souvenir</div>
    </div>

    <div class="goods-list">
      <div class="goods-item" v-for="(item,index) in list" :key="index" 
        @click="pick(item,index)" 
        :class="{ active: item.actived,disabled: (item.lastNum == 0?1:0 || item.value > score) }">
        <div class="left">
          <div>{{item.title}}</div>
          <div>{{item.title_en}}</div>
        </div>
        <div class="right">
          <div class="reason" v-if="item.value > score">
            <div>积分不足</div>
            <div>Insufficient Points</div>
          </div>
          <div class="reason" v-else-if="item.lastNum <= 0">
            <div>已兑换完</div>
            <div>Out of Stock</div>
          </div>
        </div>
      </div>
      <div class="notice">纪念品数量有限，先到先得，领完即止！</div>
      <div class="mvbutton" @click="confirmGoods" v-if="picked">
        <div>确认兑换以上纪念品</div>
        <div>Confirm to redeem the above souvenir</div>
      </div>
      <div class="mvbutton disabled" v-else>
        <div>确认兑换以上纪念品</div>
        <div>Confirm to redeem the above souvenir</div>
      </div>
    </div>
    <div class="main-btn main-btn-qt" @click="goHome">返回首页重新答题</div>
    <IDVerify :promise="verifyPromise" />
    <OpenQuestion :promise="openQuestionPromise" />
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import api from "@/api/api";
import router from "@/router";
import request from '@/api/request'
import wx from "weixin-js-sdk"
import OpenQuestion from '@/components/OpenQuestion'
import IDVerify from '@/components/IDVerify'

import { mapState, mapGetters, mapMutations } from "vuex"

export default {
  name: "Redemption",
  components: {
    OpenQuestion,
    IDVerify,
  },
  data(){
    return {
      codes:'',
      list:[],
      acid:'',
      activity:'',
      questionIndex: 0,
      score: 0,
      openQuestionPromise: {
        promise: null,
        resolve: null,
        reject: null,
      },
      verifyPromise: {
        promise: null,
        resolve: null,
        reject : null,
      },
    }
  },
  computed: {
    ...mapState(["openQuestion", "idVerify"]),
    picked() {
      return this.list.filter(v => v.actived).length > 0
    }
  },
  methods: {
    ...mapMutations([
      'setOpenQuestion', 
      'setOpenQuestionVisiable',
      'setIDVerifyVisiable',
    ]),
    goHome()
    {
      this.$router.push({
        path: '/',
      })
      return
    },
    getData()
    {
      api.detail(this.acid).then((d) => {
        if(d.data.status == 200)
        {
          if (d.data.data.hasjack) {
            this.$router.push({
              name: 'Redemlist',
            })
            return
          }
          d.data.data.list.forEach((item)=>{
            item.actived = false
          })
          this.list = d.data.data.list
          this.activity = d.data.data.activity
          this.score = d.data.data.score
        }
      })
    },
    pick(item,index) {
      if(item.lastNum==0) {
        return;
      }
      if (item.value > this.score) {
        return;
      }
      this.list.forEach((item,io)=>{
        if(io===index*1){
          item.actived = true
        }else{
          item.actived = false
        }

      })
    },
    goWx(wxpath, routeObject)
    {
      try{
        wx.miniProgram.reLaunch({
          url: wxpath
        });
      }catch (e)
      {
        this.$router.push(routeObject)
      }
    },
    verifyReserve() {
      if (this.idVerify.verified) {
        return true
      }
      this.setIDVerifyVisiable(true)
      return this.verifyPromise.promise
    },
    verifyOpenQuestion() {
      if (this.openQuestion.answered) { // 今日已答过
        return true
      }
      if (this.openQuestion.openQuestionAnswered) {
        return true
      }
      this.setOpenQuestionVisiable(true)
      return this.openQuestionPromise.promise
    },
    async confirmGoods() {
      if (! await this.verifyReserve()) {
        return
      }
      if (! await this.verifyOpenQuestion()) {
        return
      }
      let item = this.list.find(i => i.actived)
      if (!item) {
        this.$message({
          message: "请选择纪念品",
          type: 'warning'
        })
      } else {
        request.get('customize/sports-museum/drawonce', {
          params: {
            acid: item.acid,
            xid: item.id,
            odid: this.idVerify.odid.id,
          }
        }).then(r => {
          if (r.data.status == 203) {
            this.$message({
              message: "你已经领过了",
              type: 'warning'
            })
          } else if (r.data.status == 245) {
            this.$message({
              message: "已经领完了",
              type: 'warning'
            })
          } else {
            if (r.data.status != 200) {
              this.$message({
                message: r.data.message,
                type: 'warning'
              })
              return 
            }
            this.goWx('/pages/exchange/index', {
              name: 'Reservcert',
              query: {
                jid: r.data.data.id
              }
            })
          }
        })
      }
    }
  },

  mounted(){
    this.acid = this.$route.query.acid||935
    this.getData()
    api.question(942).then(r => {
      if (!r.data.data.qtoday) {
        this.setOpenQuestion(r.data.data.questions[0])
        this.openQuestionPromise.promise = new Promise((resolve, reject) => {
          this.openQuestionPromise.resolve = resolve
          this.openQuestionPromise.reject = reject
        })
      } else {
        this.setOpenQuestion({
          answered: true
        })
      }
    }).catch(e => {
      this.$message('加载开放问题失败')
    })
    this.verifyPromise.promise = new Promise((resolve, reject) => {
      this.verifyPromise.resolve = resolve
      this.verifyPromise.reject = reject
    })
  }
}
</script>

<style scoped lang="less">
.goods-body{
  /*background-color: rgba(214, 201, 172, 1);*/
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  margin-top: 30px;
}
.goods-body .goods-title>div:first-child{
  font-size: 28px;
  font-weight: 400;
}
.goods-body .goods-title>div:nth-child(2){
  font-size: 24px;
}

.goods-subtitle
{
  font-size: 16px;
  margin-top: 30px;
}

.goods-body .goods-list{
  box-sizing: border-box;
  padding: 10px;
  margin-top: 20px;
  width: 100%;
}
.goods-body .goods-list .notice{
  font-size: 16px;
  color: #242020;
  text-align: center;
  padding-bottom: 40px;
}
.goods-body .goods-list .goods-item{
  width: 100%;
  height: 54px;
  background-color: rgba(187, 138, 76, 1);
  border-radius: 0 4px 0 4px;
  box-shadow: 5px 5px 5px rgb(149 104 47);
  margin-bottom: 20px;
  display: flex;
  font-weight: 400;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 10px;
}
.goods-body .goods-list .goods-item .left {
  text-align: left;
}
.goods-body .goods-list .goods-item .right {
  text-align: right;
  font-size: 12px;
}

.goods-body .goods-list .active{
  background-color: rgba(149, 104, 47, 1);
}
.goods-body .goods-list .disabled{
  background-color: rgba(215, 215, 215, 1);
  box-shadow: 5px 5px 5px rgb(150, 150, 150);
}
.score {
  height: 50px;
  // color: rgba(149, 104, 47, 1);
  font-size: 20px;
  line-height: 2;
}
.score strong {
  // font-weight: 100;
  font-size: 24px;
}

.main-btn {
  width: 280px;
  height: 56px;
  background-color: rgba(54, 131, 116, 1);
  border: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-style: normal;
  color: #FFFFFF;
  position: fixed;
  bottom: 10px;
  margin: 0 10px;
}
.main-btn a{
  text-decoration: none;
  color: #FFFFFF;
}
</style>
